import React from "react"
import Layout from "../components/_App/layout"
import SEO from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import Footer from "../components/_App/Footer"
import PageBanner from "../components/Common/PageBanner"

const Dashboard = () => {
  return (
    <Layout>
      <SEO title="Dashboard" />

      <Navbar />

      <PageBanner pageTitle="Dashboard" />

      <div className="ptb-80">
        <div className="container">
          <iframe
            width="1200"
            height="1200"
            src="https://datastudio.google.com/embed/reporting/6399f776-0579-4d69-96df-a1204ca2e2d6/page/LrnEC"
          ></iframe>
        </div>
      </div>

      <Footer />
    </Layout>
  )
}

export default Dashboard
